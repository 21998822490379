<template>
  <div>
    <h3>
      Add New Park
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.parkDetailsForm"
    />
    <div v-if="!loading">
      <ValidationObserver ref="parkDetailsForm">
        <form @submit.prevent="onSubmit">
          <div class="accordion" role="tablist">
            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.park-information>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Primary Park Information</div>
              </div>
              <b-collapse id="park-information" visible role="tabpanel">
                <PrimaryParkInformationForm
                  :location="location"
                  @location-update="updateLocation"
                />
              </b-collapse>
            </div>
            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.park-details>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Park Details</div>
              </div>
              <b-collapse
                id="park-details"
                visible
                accordion="admin-park-details"
                role="tabpanel"
              >
                <ParkDetailsForm
                  :location="location"
                  @location-update="updateLocation"
                />
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.booking-details>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Booking Details</div>
              </div>
              <b-collapse id="booking-details" visible role="tabpanel">
                <BookingDetailsForm
                  :location="location"
                  @location-update="updateLocation"
                />
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div
                class="card-header"
                role="tab"
                v-b-toggle.activities-amenities
              >
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">
                  Activities and Amenities (POI)
                </div>
              </div>
              <b-collapse id="activities-amenities" visible role="tabpanel">
                <div class="card-body">
                  <PointsOfInterestForm
                    :location="location"
                    @location-update="updateLocation"
                  />
                </div>
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.featured-park>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Featured Park</div>
              </div>
              <b-collapse id="featured-park" visible role="tabpanel">
                <div class="card-body">
                  <FeaturedParkForm
                    :location="location"
                    @location-update="updateLocation"
                  />
                </div>
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.park-images>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Park Images</div>
              </div>
              <b-collapse id="park-images" visible role="tabpanel">
                <div class="card-body">Add images from the park edit page.</div>
              </b-collapse>
            </div>
            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.loops-campgrounds>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">
                  Associated Loops And Campgrounds
                </div>
              </div>
              <b-collapse id="loops-campgrounds" visible role="tabpanel">
                <div class="card-body">
                  Add loops and campgrounds from the park edit page. Loops are
                  required to add spots associated to this park, as the loop
                  designates the location of the spot within the park.
                </div>
              </b-collapse>
            </div>
            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.park-pricing>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">
                  Override Park Inventory Pricing
                </div>
              </div>
              <b-collapse id="park-pricing" visible role="tabpanel">
                <div class="card-body">
                  Override pricing from the park edit page.
                </div>
              </b-collapse>
            </div>
          </div>
          <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
            Save
          </button>
          <button class="btn btn-lg mt-4 mr-2" @click="cancel" type="button">
            Cancel
          </button>
        </form>
      </ValidationObserver>
      <p class="mt-2">
        <span style="color: red;">*</span> Indicates a required field
      </p>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import PrimaryParkInformationForm from "@/components/admin/parkdetails/PrimaryParkInformationForm";
import ParkDetailsForm from "@/components/admin/parkdetails/ParkDetailsForm";
import BookingDetailsForm from "@/components/admin/parkdetails/BookingDetailsForm";
import FeaturedParkForm from "@/components/admin/parkdetails/FeaturedParkForm";
import PointsOfInterestForm from "@/components/admin/parkdetails/PointsOfInterestForm";

export default {
  name: "AdminAddNewPark",
  title: "Admin - Add New Park",
  components: {
    ValidationObserver,
    FormErrorAlert,
    PrimaryParkInformationForm,
    ParkDetailsForm,
    BookingDetailsForm,
    FeaturedParkForm,
    PointsOfInterestForm
  },
  data() {
    return {
      loading: false,
      location: {
        isActive: true,
        locationPointOfInterests: []
      },
      parkStatuses: [
        { name: "Active", value: true },
        { name: "Inactive", value: false }
      ],
      operationalSeasons: [],
      regions: [],
      errors: []
    };
  },
  methods: {
    async onSubmit() {
      this.$refs.parkDetailsForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.parkDetailsForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.parkDetailsForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.location.phoneNumber = this.location.phoneNumber ?? null;
            this.location.faxNumber = this.location.faxNumber ?? null;
            const locationService = new AdminLocationService(this.tenantId);
            const response = await locationService.saveLocation(this.location);
            if (response?.statusCode === "Success") {
              this.$router.push(`/admin/park-search`).catch(() => {});
            } else {
              this.loading = false;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
              window.scrollTo(0, 0);
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    updateLocation(e) {
      this.location = { ...this.location, ...e };
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  }
};
</script>

<style scoped>
.card-header {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
