<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Park Long Name"
          id="longName"
          rules="required|tenantSiteName|min:1|max:100"
          v-model="parentLocation.longName"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">The park’s full name.</small>
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Park Short Name"
          id="shortName"
          rules="required|tenantSiteName|min:1|max:100"
          v-model="parentLocation.shortName"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          The park’s short name. Used in various description fields where full
          name is not needed.
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          name="Park Status"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="status"
            >Park Status</label
          >
          <select
            class="form-control form-control-lg"
            id="status"
            v-model="parentLocation.isActive"
            disabled
            v-bind="ariaInput"
            @change="updateParent"
          >
            <option
              v-for="(stat, index) in parkStatuses"
              :key="index"
              :value="stat.value"
              aria-placeholder="Select Park Status..."
              >{{ stat.name }}</option
            >
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >Parks designated as inactive will not be reservable.</small
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          rules="required"
          name="Operational Season"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="operationalSeason"
          >
            Operational Season
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="operationalSeason"
            v-model="parentLocation.operationalSeasonId"
            v-bind="ariaInput"
            @change="updateParent"
            :disabled="viewOnly"
          >
            <option :value="''" aria-placeholder="Select Operational Season..."
              >Select Operational Season...</option
            >
            <option
              v-for="season in operationalSeasons"
              :key="season.id"
              :value="season.id"
              aria-placeholder="Select Operational Season..."
              >{{ season.name }}</option
            >
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >The operational season selection for the park.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Park Code"
          id="parkCode"
          rules="required|tenantSiteName|min:3|max:8"
          v-model="parentLocation.code"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Three to eight letter code associated to the park.</small
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Reporting Email(s)"
          id="reportingEmail"
          rules="required"
          v-model="parentLocation.reportingEmail"
          @input="updateParent"
          type="email"
          :multiple="true"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Reporting tool distributes to emails entered in this field. Separate
          emails by comma.</small
        >
      </div>
    </div>
    <br />
    <h5>Park Map GPS Centering Coordinates</h5>
    <hr />
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Map Latitude"
          id="mapLatitude"
          rules="required|decimal|min:2|max:70"
          v-model="parentLocation.mapLatitude"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          GPS latitude coordinate in DD format (99.9999) used to locate the
          centering point for the park on the map.
        </small>
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Map Longitude"
          id="mapLongitude"
          rules="required|decimal|min:2|max:70"
          v-model="parentLocation.mapLongitude"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          GPS longitude coordinate in DD format (-99.9999) used to locate the
          centering point for the park on the map.
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import AdminTenantService from "@/services/admin/AdminTenantService.js";

export default {
  name: "PrimaryParkInformationForm",
  components: {
    ValidationProvider,
    TextInput
  },
  props: {
    location: {
      type: Object
    },
    viewOnly: Boolean
  },
  data() {
    return {
      parentLocation: {},
      parkStatuses: [
        { name: "Active", value: true },
        { name: "Inactive", value: false }
      ],
      operationalSeasons: []
    };
  },
  methods: {
    async initialize() {
      const service = new AdminTenantService(this.tenantId);
      const response = await service.getOperationalSeasons();
      if (response?.statusCode === "Success") {
        this.operationalSeasons = response.data;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    updateParent() {
      this.$emit("location-update", this.parentLocation);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  watch: {
    location() {
      this.parentLocation = JSON.parse(JSON.stringify(this.location));
    }
  },
  created() {
    this.initialize();
    this.parentLocation = JSON.parse(JSON.stringify(this.location));
  }
};
</script>

<style></style>
