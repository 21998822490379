<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <ValidationProvider
          rules="required|max:10000"
          name="General Description"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="generalDescription"
          >
            General Description
            <span class="error">*</span>
          </label>
          <ckeditor
            :config="editorConfig"
            v-model="generalDescription"
            v-bind="ariaInput"
            :read-only="viewOnly"
          />
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2">
          Information showing in the General Description field of the Park
          Details Tab.
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          rules="max:10000"
          name="Important Season Dates"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="importandSeasonDates"
            >Important Season Dates</label
          >
          <ckeditor
            :config="editorConfig"
            v-model="parentLocation.importantDates"
            v-bind="ariaInput"
            @input="updateParent"
            :read-only="viewOnly"
          />
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2">
          Important season date information shown on the Park Details Tab.
        </small>
      </div>
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          rules="required|max:10000"
          name="Directions"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="directions"
          >
            Directions
            <span class="error">*</span>
          </label>
          <ckeditor
            :config="editorConfig"
            v-model="parentLocation.directions"
            v-bind="ariaInput"
            @input="updateParent"
            :read-only="viewOnly"
          />
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >Direction information showing in the Park Details Tab.</small
        >
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Address 1"
          id="street1"
          rules="required|min:2|max:70"
          v-model="parentLocation.street1"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          Address information showing in the address section of the Park Details
          tab.
        </small>
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Address 2"
          id="street2"
          rules="min:2|max:70"
          v-model="parentLocation.street2"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          Address information showing in the address section of the Park Details
          tab.
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="City"
          id="city"
          rules="required|min:2|max:58"
          v-model="parentLocation.city"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          Address information showing in the address section of the Park Details
          tab.
        </small>
      </div>
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          name="State"
          rules="required"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="state"
          >
            State
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg mb-2"
            id="state"
            v-model="parentLocation.state"
            v-bind="ariaInput"
            @change="updateParent"
            :disabled="viewOnly"
          >
            <option
              v-for="(state, index) in states"
              :key="index"
              :value="state.abbreviation"
              >{{ state.name }}</option
            >
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2">
          Address information showing in the address section of the Park Details
          tab.
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Zip Code"
          id="zipcode"
          rules="required|min:5|zipCode"
          v-model="parentLocation.zipcode"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          Address information showing in the address section of the Park Details
          tab.
        </small>
      </div>
      <div class="col-md-6 col-sm-12"></div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Latitude"
          id="latitude"
          rules="required|decimal|min:2|max:70"
          v-model="parentLocation.latitude"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          Latitude coordinates for park in decimal degrees format (99.99999)
          showing on Park Details tab.
        </small>
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Longitude"
          id="longitude"
          rules="required|decimal|min:2|max:70"
          v-model="parentLocation.longitude"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          Longitude coordinates for park in decimal degrees format (99.99999)
          showing on Park Details tab.
        </small>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Phone"
          id="phoneNumber"
          rules="required|maskNumMin:10"
          v-model="parentLocation.phoneNumber"
          @input="updateParent"
          mask="(###) ###-####"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Phone number showing on Park Details tab.</small
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Fax"
          id="faxNumber"
          rules="maskNumMin:10"
          v-model="parentLocation.faxNumber"
          @input="updateParent"
          mask="(###) ###-####"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Fax number showing on Park Details tab.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Primary Contact Name"
          id="primaryContactName"
          rules="required|alphaNumSpaces|min:1|max:100"
          v-model="parentLocation.primaryContactName"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Primary Contact Name showing on Park Details tab.</small
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Primary Contact Email"
          id="primaryContactEmail"
          rules="required"
          v-model="parentLocation.primaryContactEmail"
          @input="updateParent"
          type="email"
          :multiple="true"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Primary Contact Email showing on Park Details tab.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Secondary Contact Name"
          id="secondaryContactName"
          rules="alphaNumSpaces|min:1|max:100"
          v-model="parentLocation.secondaryContactName"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Secondary Contact Name showing on Park Details tab.</small
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Secondary Contact Email"
          id="secondaryContactEmail"
          v-model="parentLocation.secondaryContactEmail"
          @input="updateParent"
          type="email"
          :multiple="true"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Secondary Contact Email showing on Park Details tab.</small
        >
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Website"
          id="url"
          rules="required|min:1|max:100"
          v-model="parentLocation.url"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Website Email showing on Park Details tab.</small
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Annual Visitors"
          id="annualVisitors"
          rules="numeric|between:1,1000000000"
          v-model="parentLocation.annualVisitors"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Number of annual visitors showing on Park Details tab.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Acreage"
          id="acreage"
          rules="numeric:between:1,1000000000"
          v-model="parentLocation.acreage"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Acreage for park showing on Park Details tab.</small
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Elevation"
          id="elevation"
          rules="numeric|between:1,30000"
          v-model="parentLocation.elevation"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >Elevation for park showing on Park Details tab.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          rules="required"
          name="Region"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="region"
          >
            Region
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="region"
            v-model="parentLocation.regionId"
            v-bind="ariaInput"
            @change="updateParent"
            :disabled="viewOnly"
          >
            <option
              v-for="region in regions"
              :key="region.id"
              :value="region.id"
              aria-placeholder="Select Region..."
              >{{ region.longName }}</option
            >
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2">
          Region selection for the park. Used in park filtering and shown on
          Park Details tab.
        </small>
      </div>
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          rules="required"
          name="Timezone"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="timezone"
          >
            Timezone
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="timezone"
            v-model="parentLocation.timezone"
            v-bind="ariaInput"
            @change="updateParent"
            :disabled="viewOnly"
          >
            <option
              v-for="(zone, index) in timezones"
              :key="index"
              :value="zone.name"
              >{{ zone.name }}</option
            >
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2">Time zone for the park.</small>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import states from "@/store/modules/states.js";
import timezones from "@/store/modules/timezones-US.js";
import ckconfig from "@/helpers/ckeditorConfig.js";

export default {
  name: "ParkDetailsForm",
  components: {
    ValidationProvider,
    TextInput
  },
  props: {
    location: {
      type: Object
    },
    viewOnly: Boolean
  },
  data() {
    return {
      parentLocation: {},
      regions: [],
      editorConfig: ckconfig
    };
  },
  methods: {
    async initialize() {
      const service = new AdminLocationService(this.tenantId);
      const response = await service.getRegions();
      if (response?.statusCode === "Success") {
        this.regions = response.regions;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    updateParent() {
      this.$emit("location-update", this.parentLocation);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    states() {
      return states;
    },
    timezones() {
      return timezones;
    },
    generalDescription: {
      get() {
        return this.parentLocation?.locationDescriptions?.find(
          x => x.title === "General Description"
        )?.description;
      },
      set(val) {
        const generalDescription = this.parentLocation?.locationDescriptions?.find(
          x => x.title === "General Description"
        );
        if (generalDescription) {
          this.parentLocation.locationDescriptions[
            this.parentLocation.locationDescriptions.indexOf(generalDescription)
          ].description = val;
        } else {
          if (!this.parentLocation.locationDescriptions) {
            this.parentLocation.locationDescriptions = [];
          }
          this.parentLocation.locationDescriptions.push({
            description: val,
            displayOrder: 1,
            tenantId: this.tenantId,
            title: "General Description"
          });
        }
        this.updateParent();
      }
    }
  },
  watch: {
    location() {
      this.parentLocation = JSON.parse(JSON.stringify(this.location));
    }
  },
  created() {
    this.initialize();
    this.parentLocation = JSON.parse(JSON.stringify(this.location));
  }
};
</script>

<style></style>
