var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"max:10000" + "" + (_vm.parentLocation.featured ? '|required' : ''),"name":"Featured Park Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
return [_c('label',{class:{ error: errors[0] },attrs:{"for":"featuredDescription"},on:{"click":function($event){return _vm.$refs.select.focus()}}},[_vm._v(" Featured Park Description "),(_vm.parentLocation.featured)?_c('span',{staticClass:"error"},[_vm._v("*")]):_vm._e()]),_c('ckeditor',_vm._b({attrs:{"config":_vm.editorConfig,"id":"featuredDescription","read-only":!_vm.parentLocation.featured || _vm.viewOnly},on:{"input":_vm.updateParent},model:{value:(_vm.parentLocation.featuredDescription),callback:function ($$v) {_vm.$set(_vm.parentLocation, "featuredDescription", $$v)},expression:"parentLocation.featuredDescription"}},'ckeditor',ariaInput,false)),(errors.length > 0)?_c('ul',{staticClass:"mt-1 mb-0 pl-3"},_vm._l((errors),function(error,index){return _c('li',_vm._b({key:index,staticClass:"error"},'li',ariaMsg,false),[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]}}])}),_c('small',{staticClass:"form-text text-muted mb-2"},[_vm._v(" Text showing in the featured parks section on the home page. ")])],1),_c('div',{staticClass:"col-md-3 col-sm-12 mt-3"},[_c('label',{staticClass:"mt-4"},[_c('input',{attrs:{"type":"checkbox","id":"featured","disabled":_vm.viewOnly},domProps:{"checked":_vm.parentLocation.featured},on:{"input":function () {
              _vm.parentLocation.featured = !_vm.parentLocation.featured;
              this$1.updateParent();
            }}}),_vm._v(" Featured Park Designation ")]),_c('small',{staticClass:"form-text text-muted mb-2"},[_vm._v(" Checking here designates park as ‘Featured’. Park will be listed in a special section on the customer homepage. ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }