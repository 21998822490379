export default [
  { name: "America/Adak" },
  { name: "America/Anchorage" },
  { name: "America/Boise" },
  { name: "America/Chicago" },
  { name: "America/Denver" },
  { name: "America/Detroit" },
  { name: "America/Indiana/Indianapolis" },
  { name: "America/Indiana/Knox" },
  { name: "America/Indiana/Marengo" },
  { name: "America/Indiana/Petersburg" },
  { name: "America/Indiana/Tell_City" },
  { name: "America/Indiana/Vevay" },
  { name: "America/Indiana/Vincennes" },
  { name: "America/Indiana/Winamac" },
  { name: "America/Juneau" },
  { name: "America/Kentucky/Louisville" },
  { name: "America/Kentucky/Monticello" },
  { name: "America/Los_Angeles" },
  { name: "America/Menominee" },
  { name: "America/Metlakatla" },
  { name: "America/New_York" },
  { name: "America/Nome" },
  { name: "America/North_Dakota/Beulah" },
  { name: "America/North_Dakota/Center" },
  { name: "America/North_Dakota/New_Salem" },
  { name: "America/Phoenix" },
  { name: "America/Sitka" },
  { name: "America/Yakutat" },
  { name: "Pacific/Honolulu" }
];
