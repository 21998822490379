<template>
  <div>
    <div class="row">
      <div class="col-md-9 col-sm-12">
        <ValidationProvider
          :rules="`max:10000` + `${parentLocation.featured ? '|required' : ''}`"
          name="Featured Park Description"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="featuredDescription"
          >
            Featured Park Description
            <span class="error" v-if="parentLocation.featured">*</span>
          </label>
          <ckeditor
            :config="editorConfig"
            v-model="parentLocation.featuredDescription"
            v-bind="ariaInput"
            id="featuredDescription"
            @input="updateParent"
            :read-only="!parentLocation.featured || viewOnly"
          />
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2">
          Text showing in the featured parks section on the home page.
        </small>
      </div>
      <div class="col-md-3 col-sm-12 mt-3">
        <label class="mt-4">
          <input
            type="checkbox"
            :checked="parentLocation.featured"
            id="featured"
            :disabled="viewOnly"
            @input="
              () => {
                parentLocation.featured = !parentLocation.featured;
                this.updateParent();
              }
            "
          />
          Featured Park Designation
        </label>
        <small class="form-text text-muted mb-2">
          Checking here designates park as ‘Featured’. Park will be listed in a
          special section on the customer homepage.
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import ckconfig from "@/helpers/ckeditorConfig.js";
export default {
  name: "FeaturedParkForm",
  components: {
    ValidationProvider
  },
  props: {
    location: {
      type: Object
    },
    viewOnly: Boolean
  },
  data() {
    return {
      parentLocation: {},
      editorConfig: ckconfig
    };
  },
  methods: {
    updateParent() {
      this.$emit("location-update", this.parentLocation);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  watch: {
    location() {
      this.parentLocation = JSON.parse(JSON.stringify(this.location));
    }
  },
  created() {
    this.parentLocation = JSON.parse(JSON.stringify(this.location));
  }
};
</script>

<style></style>
