<template>
  <div>
    <div class="row">
      <div v-if="parentLocation.locationPointOfInterests.length === 0">
        <div class="col-12">
          <div>No amenities associated for this park.</div>
        </div>
      </div>
      <div class="col-12">
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="tableLocationPointsOfInterest"
          stacked="md"
          small
          class="mt-4"
          bordered
          sort-icon-left
          v-if="parentLocation.locationPointOfInterests.length > 0"
        >
          <template v-slot:cell(distanceDescriptionName)="data">
            <div v-if="modifiedPointOfInterestId !== data.item.id">
              {{ data.item.distanceDescriptionName }}
            </div>
            <div v-else>
              <select
                class="form-control form-control-lg mb-2"
                id="pointOfInterestDistanceDescription"
                v-model="data.item.pointOfInterestDistanceDescriptionId"
                @change="
                  updateItem(
                    data.index,
                    'pointOfInterestDistanceDescriptionId',
                    data.item.pointOfInterestDistanceDescriptionId
                  )
                "
              >
                <option
                  v-for="item in distanceDescriptions"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</option
                >
              </select>
            </div>
          </template>

          <template v-slot:cell(coordinates)="data">
            <div v-if="modifiedPointOfInterestId !== data.item.id">
              <strong>Latitude:</strong>
              {{ data.item.latitude }}
              <strong>Longitude:</strong>
              {{ data.item.longitude }}
            </div>
            <div v-else class="row">
              <TextInput
                class="col-md-6 col-sm-12"
                id="latitude"
                :hideLabel="true"
                placeholder="Enter latitude"
                v-model="data.item.latitude"
                @change="updateItem(data.index, 'latitude', data.item.latitude)"
              />
              <TextInput
                class="col-md-6 col-sm-12"
                id="longitude"
                :hideLabel="true"
                placeholder="Enter longitude"
                v-model="data.item.longitude"
                @change="
                  updateItem(data.index, 'longitude', data.item.longitude)
                "
              />
            </div>
          </template>
          <template v-slot:cell(id)="data">
            <button
              class="btn btn-primary btn-sm mr-2"
              type="button"
              @click="modifyPointOfInterest(data.item.id)"
              :disabled="viewOnly"
            >
              {{
                modifiedPointOfInterestId !== data.item.id ? "Modify" : "Save"
              }}
            </button>
            <button
              class="btn btn-primary btn-sm mr-2"
              type="button"
              @click="deletePointOfInterest(data.item.id)"
              :disabled="viewOnly"
            >
              Delete
            </button>
          </template>
        </b-table>
        <div v-if="addingPointOfInterest" class="row">
          <div class="col-3">
            <select
              class="form-control form-control-lg mb-2"
              id="pointOfInterest"
              v-model="newPointOfInterest.pointOfInterestId"
            >
              <option value>Select Amenity...</option>
              <option
                v-for="item in pointsOfInterest"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</option
              >
            </select>
          </div>
          <div class="col-3">
            <select
              class="form-control form-control-lg mb-2"
              id="pointOfInterestDistanceDescription"
              v-model="newPointOfInterest.pointOfInterestDistanceDescriptionId"
              :disabled="!newPointOfInterest.pointOfInterestId"
            >
              <option value>Select Distance Description...</option>
              <option
                v-for="item in distanceDescriptions"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</option
              >
            </select>
          </div>
          <div class="col-3">
            <div class="row">
              <TextInput
                class="col-6"
                id="latitude"
                :hideLabel="true"
                rules="decimal"
                placeholder="Enter Latitude"
                v-model="newPointOfInterest.latitude"
                :disabled="!newPointOfInterest.pointOfInterestId"
              />
              <TextInput
                class="col-6"
                id="longitude"
                :hideLabel="true"
                rules="decimal"
                placeholder="Enter Longitude"
                v-model="newPointOfInterest.longitude"
                :disabled="!newPointOfInterest.pointOfInterestId"
              />
            </div>
          </div>
          <div class="col-2 ml-5">
            <button
              class="btn btn-primary btn-sm ml-3 mr-2"
              type="button"
              @click="cancelAddingNewPointOfInterest()"
            >
              Delete
            </button>
            <button
              class="btn btn-primary btn-sm mr-2"
              type="button"
              @click="addPointOfInterest()"
              :disabled="
                !newPointOfInterest.pointOfInterestDistanceDescriptionId
              "
            >
              Add
            </button>
          </div>
        </div>
        <button
          class="btn btn-primary mr-2"
          type="button"
          @click="selectPointOfInterest()"
          v-if="!addingPointOfInterest && !viewOnly"
        >
          {{
            this.parentLocation.locationPointOfInterests.length > 0
              ? "Select another Amenity"
              : "Select Amenity"
          }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "PointsOfInterestForm",
  props: {
    location: {
      type: Object
    },
    viewOnly: Boolean
  },
  components: {
    TextInput
  },
  data() {
    return {
      parentLocation: {},
      modifiedPointOfInterestId: null,
      addingPointOfInterest: false,
      newPointOfInterest: {
        pointOfInterestId: "",
        pointOfInterestDistanceDescriptionId: ""
      },
      pointsOfInterest: [],
      distanceDescriptions: [],
      fields: [
        { key: "pointOfInterestName", sortable: true, label: "Amenity Name" },
        {
          key: "distanceDescriptionName",
          sortable: true,
          label: "Distance"
        },
        { key: "coordinates", label: "GPS Coordinates" },
        {
          key: "id",
          label: "Actions/Options",
          class: "actionsColumn"
        }
      ]
    };
  },
  methods: {
    async initialize() {
      this.getPointsOfInterest();
      this.getDistanceDescriptions();
    },
    async getPointsOfInterest() {
      const lookupService = new AdminLookupService(this.tenantId);
      const response = await lookupService.getPointsOfInterest({
        isSearchable: false
      });
      if (response?.statusCode === "Success") {
        this.pointsOfInterest = response.data;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    async getDistanceDescriptions() {
      const service = new AdminLocationService(this.tenantId);
      const response = await service.getPointsOfInterestDistanceDescriptions();
      if (response?.statusCode === "Success") {
        this.distanceDescriptions = response.data;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    modifyPointOfInterest(id) {
      if (this.modifiedPointOfInterestId !== id) {
        this.modifiedPointOfInterestId = id;
      } else {
        this.modifiedPointOfInterestId = null;
        this.updateParent();
      }
    },
    deletePointOfInterest(id) {
      const index = this.parentLocation.locationPointOfInterests.indexOf(
        this.parentLocation.locationPointOfInterests.find(x => x.id === id)
      );
      this.parentLocation.locationPointOfInterests.splice(index, 1);
      this.updateParent();
    },
    updateParent() {
      this.$emit("location-update", this.parentLocation);
    },
    getDistanceDescriptionName(id) {
      return this.distanceDescriptions?.find(x => x.id === id)?.name;
    },
    getPointOfInterestName(locationPointOfInterest) {
      const name = this.pointsOfInterest?.find(
        x => x.id === locationPointOfInterest.pointOfInterestId
      )?.name;
      return name ? name : locationPointOfInterest?.pointOfInterest?.name;
    },
    updateItem(index, key, value) {
      this.parentLocation.locationPointOfInterests[index][key] = value;
    },
    selectPointOfInterest() {
      this.addingPointOfInterest = true;
    },
    cancelAddingNewPointOfInterest() {
      (this.addingPointOfInterest = false),
        (this.newPointOfInterest = {
          pointOfInterestId: "",
          pointOfInterestDistanceDescriptionId: ""
        });
    },
    addPointOfInterest() {
      this.parentLocation.locationPointOfInterests.push({
        ...this.newPointOfInterest,
        tenantId: this.tenantId,
        locationId: this.parentLocation.id
      });
      this.updateParent();
      this.cancelAddingNewPointOfInterest();
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    tableLocationPointsOfInterest() {
      const tableLocationPointsOfInterest = [];
      this.parentLocation.locationPointOfInterests.forEach(x => {
        tableLocationPointsOfInterest.push({
          pointOfInterestName: this.getPointOfInterestName(x),
          distanceDescriptionName: this.getDistanceDescriptionName(
            x.pointOfInterestDistanceDescriptionId
          ),
          pointOfInterestDistanceDescriptionId:
            x.pointOfInterestDistanceDescriptionId,
          latitude: x.latitude,
          longitude: x.longitude,
          id: x.id
        });
      });
      return tableLocationPointsOfInterest;
    }
  },
  watch: {
    location() {
      this.parentLocation = JSON.parse(JSON.stringify(this.location));
    }
  },
  created() {
    this.parentLocation = JSON.parse(JSON.stringify(this.location));
    this.initialize();
  }
};
</script>
