<template>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <ValidationProvider
          rules="max:10000"
          name="Booking Details"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="bookingDetails"
            >Booking Details</label
          >
          <ckeditor
            :config="editorConfig"
            v-model="bookingDetails"
            v-bind="ariaInput"
            id="bookingDetails"
            @input="updateParent"
            :read-only="viewOnly"
          />
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2">
          Text showing in the Reservation Information field of the Booking
          Details tab
        </small>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import ckconfig from "@/helpers/ckeditorConfig.js";
export default {
  name: "BookingDetailsForm",
  props: {
    location: {
      type: Object
    },
    viewOnly: Boolean
  },
  components: {
    ValidationProvider
  },
  data() {
    return {
      parentLocation: {},
      editorConfig: ckconfig
    };
  },
  methods: {
    updateParent() {
      this.$emit("location-update", this.parentLocation);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    bookingDetails: {
      get() {
        return this.parentLocation?.locationDescriptions?.find(
          x => x.title === "Reservation Info"
        )?.description;
      },
      set(val) {
        const bookingDetails = this.parentLocation?.locationDescriptions?.find(
          x => x.title === "Reservation Info"
        );
        if (bookingDetails) {
          this.parentLocation.locationDescriptions[
            this.parentLocation.locationDescriptions.indexOf(bookingDetails)
          ].description = val;
        } else {
          if (!this.parentLocation.locationDescriptions) {
            this.parentLocation.locationDescriptions = [];
          }
          this.parentLocation.locationDescriptions.push({
            description: val,
            displayOrder: 1,
            tenantId: this.tenantId,
            title: "Reservation Info"
          });
        }
        this.updateParent();
      }
    }
  },
  watch: {
    location() {
      this.parentLocation = JSON.parse(JSON.stringify(this.location));
    }
  },
  created() {
    this.parentLocation = JSON.parse(JSON.stringify(this.location));
  }
};
</script>
